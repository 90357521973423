import React, { useEffect, useRef, useState } from 'react';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import useAIService from "../helpers/useAIService";
import useBingSearch from "../helpers/useBingSearch";
import Search from '../components/Search';
import "./Results.css";
import "./BasicChat.css"
import Refine from "../components/AskFollowUp";
import {useNavigate} from "react-router-dom"
import AtomicSpinner from 'atomic-spinner';
import startConversationHistory from "../mock/StartConversationHistory"
import bingResponse from '../mock/Response';
import { Button } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ShareIcon from '@mui/icons-material/Share';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import "./BasicChat.css"
import FiberNewIcon from '@mui/icons-material/FiberNew';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import ChatIcon from '@mui/icons-material/Chat';
import { useAuthWrapper } from './AuthWrapper';

function Results() {
  
  const [{ term, followup, conversationHistory, loading, streaming, nextQuestion, chatMode, user, subscription }, dispatch] = useStateValue();
  //const data = useBingSearch(term);
  const refToScrollableDiv = useRef(null);
  useAIService(term, followup, refToScrollableDiv, user, subscription);
  //useAuthWrapper();

  const displayConversationHistory = JSON.parse(JSON.stringify(conversationHistory));
  //console.log(`display ${JSON.stringify(conversationHistory)}`);
  
  const [autoScroll, setAutoScroll] = useState(true);

  const placeholders = [
    "Ask AI e.g, Stock trading overview",
    "Ask AI e.g, Is milk part of Keto diet?",
    "Ask AI e.g, Explain airplane turbulence",
    "Ask AI e.g, What is the NIFTY 50?",
    "Ask AI e.g, Write happy birthday greeting",
    "Ask AI e.g, Write OOO email to my manager",
    "Ask AI e.g, Keto meal recipe for breakfast",
    "Ask AI e.g, Cheese cake recipe",
    "Ask AI e.g, Plan a 3-day trip to Goa",
    "Ask AI e.g, Prepare GMAT study plan",
    "Ask AI e.g, Plan resistance training workout",
    "Ask AI e.g, Prepare GRE study plan",
    "Ask AI e.g, Prepare CBSE study plan",
    "Ask AI e.g, Plan a 3-day trip to Goa",
    "Ask AI e.g, Simplified stock trading explanation",
    "Ask AI e.g, Simplified options trading explanation",
    "Ask AI e.g, Retro-style game concept ideas",
    "Ask AI e.g, Halloween dress selection ideas",
    "Ask AI e.g, Generate 3 ideas for startups"
];

  
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  // const [intervalTime, setIntervalTime] = useState(10000);

  useEffect(()=>{
    
    const interval = setInterval(()=>{
      setIndex(u=> (u+1) % placeholders.length)
    }, 6000);
    if(term !== "")
      clearInterval(interval);
    return () => clearInterval(interval);
  },[term])


  // useEffect(()=>{
  //   // if(!!refToScrollableDiv && !!refToScrollableDiv.current){
  //   //   refToScrollableDiv.current.scrollTo({
  //   //     top: 0,
  //   //     behavior: "smooth"
  //   //   })
  //   // }
  // },[loading])


  const handleCopyClick = async (u) => {
    try {
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(u, 'text/html');
      const textToCopy = parsedHtml.body.textContent || '';
      await navigator.clipboard.writeText(textToCopy);
      console.log("paste and verify");
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  // useEffect(()=>{
  //   if (streaming && !loading &&  refToScrollableDiv && refToScrollableDiv.current) {
  //     console.log(refToScrollableDiv.current.height)
  //     // refToScrollableDiv.current.scrollTo({
  //     //   top: refToScrollableDiv.current.scrollHeight,
  //     //   behavior: "smooth"
  //     // });
  //   }
        
  // }, [streaming, loading])
  // if (streaming && refToScrollableDiv && refToScrollableDiv.current) {
  //   refToScrollableDiv.current.scrollTo({
  //     top: refToScrollableDiv.current.scrollHeight,
  //     behavior: "auto"
  //   });
  // }

  // if(refToScrollableDiv && refToScrollableDiv.current){
  //   console.log(refToScrollableDiv.current.offsetHeight);
  // }
  
//   useEffect(() => {
//     // if (refToScrollableDiv.current && streaming) {
//     //     refToScrollableDiv.current.scrollTop = refToScrollableDiv.current.scrollHeight;
//     // }
//     if (refToScrollableDiv.current && streaming) {
//       refToScrollableDiv.current.scrollTop = 1;
//       // Handle scroll events to ensure the scrollbar remains visible
//       const scrollContainer = refToScrollableDiv.current;
//       const handleScroll = () => {
//         if (scrollContainer.scrollTop === 0) {
//           scrollContainer.scrollTop = 1;
//         } else if (
//           scrollContainer.scrollTop + scrollContainer.clientHeight ===
//           scrollContainer.scrollHeight
//         ) {
//           scrollContainer.scrollTop -= 1;
//         }
//       };

//       scrollContainer.addEventListener('scroll', handleScroll);
//       return () => {
//         // Clean up the event listener when the component unmounts
//         scrollContainer.removeEventListener('scroll', handleScroll);
//       };
      
//   }
 
// },[]);

// useEffect(() => {
//   if (refToScrollableDiv.current && followup.length>0) {
//       refToScrollableDiv.current.scrollTop = refToScrollableDiv.current.scrollHeight;
//   }
// },[followup]);

useEffect(() => {
  
  if(conversationHistory.length<2) return;//only worry about scrolling down when [sys,user,assistant] or more

  if(!refToScrollableDiv.current) return;//ref to scrollable container must not be null

  if(autoScroll && streaming){ //streaming must be true, if no streaming stay at top
  //  refToScrollableDiv.current.scrollTop = refToScrollableDiv.current.scrollHeight;//push top to bottom, stop if user interrups
    const { scrollHeight } = refToScrollableDiv.current;
    refToScrollableDiv.current.scrollTo(0, scrollHeight);
  }
  if(followup.length>0){
    // refToScrollableDiv.current.scrollTop = refToScrollableDiv.current.scrollHeight;
    const { scrollHeight } = refToScrollableDiv.current;
    refToScrollableDiv.current.scrollTo(0, scrollHeight);
  }
  

  // if (refToScrollableDiv.current && followup.length>0) {
  //     refToScrollableDiv.current.scrollTop = refToScrollableDiv.current.scrollHeight;
  // }
},[conversationHistory, autoScroll, followup]); 
//conversationHistory[conversationHistory.length-1]["role"]

useEffect(()=>{
  if(term.length>0 || followup.length>0) setAutoScroll(true);
},[term, followup])

// useEffect(()=>{refToScrollableDiv.current.scrollTop = refToScrollableDiv.current.scrollHeight;})
useEffect(()=>{
  const scrollContainer = refToScrollableDiv.current;
  if(scrollContainer){
    scrollContainer.addEventListener("mousedown", ()=>setAutoScroll(false));
    scrollContainer.addEventListener("touchstart", ()=>setAutoScroll(false));
    scrollContainer.addEventListener("click", ()=>setAutoScroll(false));
  }

  return () => {
    if(scrollContainer){
      scrollContainer.removeEventListener("mousedown", ()=>setAutoScroll(false));
      scrollContainer.removeEventListener("touchstart", ()=>setAutoScroll(false));
      scrollContainer.removeEventListener("click", ()=>setAutoScroll(false));
    }
  }

},[])

const focusButtonStyle = () => {
  return {borderRadius: "18px", opacity:"0.9", marginLeft:"5px",  marginRight:"5px", boxShadow:"2px 4px 12px rgba(0,0,0,.5)", background: "black", paddingLeft:"16px", marginRight:"8px", fontSize:"small", color:"white"};
}

  return (
    <div className='resultsPage' style={{height:"100vh", overflowY:"auto"}}>
      {/* <div style={{height: "5vh !important",padding:"3%", fontFamily: "Roboto, sans-serif", display:"flex", alignItems:"center", color:"rgb(52, 52, 52)"}}> */}
      {/* <div className='resultsPage__banner'> */}
                    {/* <AutoAwesomeIcon/> */}
                    {/* <AllInclusiveIcon onClick={(_)=> window.location.href = "https://chatdragon.ai/"} /> */}
                    {/* <div style={{ display: 'inline-block', margin: 0, padding: 0, cursor: 'pointer', fontWeight:"500", marginLeft:"2%", fontSize:"20px" }} onClick={(_)=> {dispatch({type:actionTypes.SET_FOLLOWUP_TERM, followup: "" }); dispatch({type:actionTypes.SET_STREAMING, streaming:false }); dispatch({type:actionTypes.SET_LOADING, loading:false}); navigate("/")}}>ChatDragon.ai</div> */}
                {/* </div> */}
                {/* {chatMode && <div className="container_header">
                <div className="container_header_banner" style={{fontFamily:"Roboto,sans-serif", margin:"5%"}} onClick={(_)=> window.location.href = "https://chatdragon.ai/"}>
                    <AllInclusiveIcon style={{width:"35px", height:"35px"}}/>
                    <h1 style={{ fontWeight: 500, marginLeft:"2%", opacity:"0.9" }}>ChatDragon.ai</h1>
                </div>
                
                <hr style={{border: "none", height:"1px", background: "rgba(128, 128, 128, 0.2)" }} />
            </div>} */}
                  {
                    <div className='resultsPage__header'>
                      <div style={{display:"flex", alignItems:"baseline"}} className='resultsPage__header__prompt__entry'>
                          <AllInclusiveIcon style={{marginRight:"2.5%"}} onClick={(_)=> {dispatch({type:actionTypes.RESET_STATE});; navigate("/");}} />            
                        {!chatMode && <Search w={"97%"} placeholder={"Write something new.."} showClear={true} />}
                        {chatMode && <h1 onClick={(_)=> {dispatch({type:actionTypes.RESET_STATE});; navigate("/query"); }} style={{ fontWeight: 500, opacity:"0.9", fontFamily: "'Roboto', sans-serif" }}>ChatDragon.ai</h1>}
                      </div>
                      {/* <div style={{textAlign:"center"}}>
                      <Button disabled={streaming} startIcon={<ChatIcon />} style={focusButtonStyle()} onClick={_ =>{ navigate("/query")}}>Chat</Button>
                      <Button disabled={streaming} startIcon={<CreateIcon />} style={{color:"black"}} onClick={_ =>{ navigate("/docs")}}>Edit (Beta)</Button>
                      <Button disabled={streaming} startIcon={<ClearAllIcon />} style={{color:"black"}} onClick={_ =>{ dispatch({type:actionTypes.RESET_STATE}); }}>Clear All</Button>
                      </div> */}
                    </div>
            }
  
          {/* <div style={{display:"flex", justifyItems:"end"}} className='resultsPage__header__prompt__entry'>
            <AllInclusiveIcon onClick={(_)=> window.location.href = "https://chatdragon.ai/"} />
            <Search placeholder={"Start New Chat, Ask AI anything.."} showClear={true} />
          </div> */}
        
        {/* <div className='resultsPage__body'> */}
        <div className='resultsPage__body' ref={refToScrollableDiv}>
            <div className='resultsPage__ai__response'>
              
            {/* <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && (<AtomicSpinner style={{ width: '250px', height: '250px' }} />)}
              </div> */}
              {/* <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && (<AtomicSpinner style={{ width: '50px', height: '50px' }} />)}
              </div> */}


            {(
              displayConversationHistory
               // .reverse() // This will reverse the order of the array
                .filter(u=>u.role === "assistant")
                .map((u, _) => {
                  return (<div className='ai__display'>
                            <div className='ai__display__assistant'> 
                              {
                                // index === 0 ? <StreamParagraph paragraph={u.content}/> : <div>{u.content}</div>
                                //style={{whiteSpace:"pre-line"}}
                                <>
                                  {/* {!streaming && <Button startIcon={<CreateIcon />} style={{marginLeft:"0%"}} onClick={_ =>{
                                     navigate("/docs")
                                      
                                    }}>Edit Mode</Button>} */}
                                  <div style={{whiteSpace: "pre-line", padding: "1%", paddingTop:"3.5%", paddingBottom:"2%"}} dangerouslySetInnerHTML={{__html: u.content}}></div>
                                        
                                    {!streaming && <Button startIcon={<ShareIcon />} style={{marginLeft:"80%"}} onClick={async _ =>{
                                      try{
                                        await handleCopyClick(u.content); // copy to clipboard
                                        const copiedText = await navigator.clipboard.readText();
                                        await navigator.share({
                                          text: copiedText
                                        });
                                    //    window.gtag('event', 'Share');
                                      } catch (_){
                                        console.error("Failed to Copy");
                                      }
                                      
                                    }}>Share</Button>}
                                    {!streaming  && <Button onClick={async (e) => {
                                      await handleCopyClick(u.content);
                                    //  window.gtag('event', 'Copy');
                                    }} style={{marginLeft:"80%"}} startIcon={<ContentCopyIcon/>}>Copy</Button>}

                                    {/* {!streaming && <Button onClick={async (e) => {
                                      await handleCopyClick(u.content);
                                    
                                    }} style={{marginLeft:"80%"}} startIcon={<PsychologyIcon/>}>How?</Button>} */}
                                  
                                  <hr style={{marginTop:"1%", opacity:"0.3"}}/>
                                  {/* {streaming && (<div style={{ width: '50px', height: '50px' }} />)}  */}
                                </>
                                //<hr style={{marginTop:"2%"}}/>  
                              }
                            </div>
                          </div>)
                })
            )}
             {/* <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading && (<AtomicSpinner style={{ width: '50px', height: '50px' }} />)}
              </div> */}
            { loading && <div className='ai__display'>
                            <div className='ai__display__assistant'> <div className='resultsPage__ai__response__spinner' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
               <AtomicSpinner style={{ width: '250px', height: '250px' }} />
              </div></div></div>
            }
              {/* <Refine /> */}
              
              {/* {!!data.data &&  (
              
              <div className='bingResults__container'>

                {data.data?.webPages.value.map(item=>(
                  <div className='bingResults__container__searchResult'>
                    <div style={{display:"flex"}}>
                      <div style={{display:"flex", flexDirection:"column"}}>
                          <a className='' href={item.displayUrl}>
                            <h3>{item.name}</h3>
                          </a>
                          <h5>{item.displayUrl}</h5>
                        </div>
                    </div>
                    <p className=''>
                      {item.snippet}
                    </p>
                  </div>
                ))
                }
            </div>
            )} */}

            </div>

        </div>
        <div className='resultsPage__footer'>         
           <Refine refToScrollableDiv = {refToScrollableDiv} showQuestions={true} />
        </div>
      
    </div>
  );
}

export default Results;

/**
 * {!!data.data &&  (
              
              <div className='resultsPage__results'>

                {data.data?.webPages.value.map(item=>(
                  <div className='resultsPage__result'>
                    <a className='resultsPage__result__title' href={item.displayUrl}>
                      <h3>{item.name}</h3>
                    </a>
                    <div className='resultsPage__ai__title_h5'>
                      <h5>{item.displayUrl}</h5>
                    </div>
                    <p className='resultsPage__result__snippet'>
                      {item.snippet}
                    </p>
                  </div>
                ))
                }
            </div>
            )}
 * 
 */


  //const data = useBingSearch(term);
  //const data = [];

{/* <Link to="/">
          <img className='resultsPage__header__logo'
               src="basic.png" 
               alt="Langmod Logo"
               aria-label="Langmod Logo"
               tabIndex={1} />
          </Link> */}

 