import './App.css';
import Results from './pages/Results';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BasicChat from './pages/BasicChat';
import PrivacyPolicy from "./pages/Privacy";
import Docs from "./pages/Docs";
import { loadStripe } from '@stripe/stripe-js';
import { Login } from './pages/Login';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import CustomerSupport from './pages/CustomerSupport';
import TermsOfService from './pages/TermsOfService';
import Cancellation from './pages/Cancellation';


function App() {

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_ID);

  /**
   * chatdragon.ai
     chatdragon.ai/privacy (Privacy Policy)
     chatdragon.ai/support (Customer Support)
     chatdragon.ai/terms (Terms of Service)
     chatdragon.ai/cancellation (Cancellation)
   */
     console.log(`Max Trials allowed for Single Shot, to Number: ${Number(process.env.REACT_APP_FREE_TRIAL)}`)
  return (
      <div className="app">
         <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
             <Elements stripe={stripePromise}>  
              <Router>
                    <Routes>
                      <Route exact path="login" element={<Login />} />
                      <Route exact path="/query" element={<Results />} />
                      <Route exact path="/" element={<BasicChat />} />
                      <Route exact path="/docs" element={<Docs />} />
                      <Route exact path="/privacy" element={<PrivacyPolicy />} />
                      <Route exact path="/customer-support" element={<CustomerSupport />} />
                      <Route exact path="/terms-of-service" element={<TermsOfService />} />
                      <Route exact path="/cancellation" element={<Cancellation />} />
                      {/* <Route path="/success" element={<Success/>} />
                      <Route path="/cancelled" element={<Cancelled />} /> */}
                    </Routes>
              </Router>
            </Elements> 
        </GoogleOAuthProvider>
       
      </div>
   
      
   
    
  );
}

export default App;

