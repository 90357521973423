import React from 'react';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { useNavigate } from "react-router-dom"
import { useAuthWrapper } from './AuthWrapper';
import Header from './Header';
import Footer from './Footer';

const PrivacyPolicy = () => {

    const navigate = useNavigate();
    useAuthWrapper();

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{fontWeight:400}}>Privacy Policy</h1>
                            <br />
                            <p><i>Last updated: Jan 9th, 2024</i></p>
                            <br /><br />
                            <h2>Our Services</h2>
                            <br />
                            <p>
                                ChatDragon.ai offers a text generation service. ChatDragon.ai can be accessed through{' '}
                                <a href="https://www.chatdragon.ai">https://www.chatdragon.ai</a> and its associated internet domains.
                            </p>
                            <br /><br />
                            <h2>Information ChatDragon.ai Collects</h2>
                            <br />
                            <p>
                                Upon visiting our website, specific information may be automatically collected from your device.
                                This information could encompass your IP address, device type, unique device identification numbers,
                                browser type, general geographic location (such as country or city-level location), and
                                additional technical data.
                                Furthermore, we may gather data about your device's interaction with our website,
                                including prompt examples clicked. We employ this information for internal analytics and to enhance the
                                quality and relevance of our service.
                                No user data is used to train any models used for text generation.
                            </p>

                            {/* Add more sections here... */}

                            <br /><br />
                            <h2>Contact Us</h2>
                            <br />
                            <p>If you have any inquiries regarding this Policy, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                        </div>

                    </div>

                </div>

            </div>
            <Footer position='none' />
            {/* <div className="footer" style={{background:"black",opacity:"0.9", bottom:"0", width:"100%"}}>
                <div className="footer__inner" style={{padding:"1.5rem", color:"white", display:"flex", fontSize:"13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'}}>

                    <div style={{margin:"0 1.8%"}}><a style={{textDecoration:"none", color:"inherit"}} href="mailto: support@writechat.ai">Contact Us</a></div>
                    <div style={{margin:"0 1.8%"}}><a style={{textDecoration:"none", color:"inherit"}} href = "mailto: support@writechat.ai?subject=Re:%20Careers&body=Please%20attach%20your%20CV">Careers</a></div> 
                    <div style={{margin:"0 1.8%"}}><a style={{textDecoration:"none", color:"inherit"}} href = "https://writechat.ai/privacy">Privacy</a></div>
                </div>
                
            </div> */}
        </div>
    );
};

export default PrivacyPolicy;
