import React, { useEffect, useState, useRef } from 'react'
import "./Search.css";
import SearchIcon from '@mui/icons-material/Search';
import SendIcon from '@mui/icons-material/Send';
import { Button } from '@mui/material';
import {useNavigate} from "react-router-dom"
import { useStateValue } from "../statemanagement/StateProvider"
import { actionTypes } from '../statemanagement/reducer';
import { Carousel } from 'react-responsive-carousel';
import PromptCarousel from "./PromptCarousel";
import LandingPrompts from "./LandingPrompts";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ClearIcon from '@mui/icons-material/Clear';
import {submitCustomEvent} from "../helper/customevents";
import MicIcon from '@mui/icons-material/Mic';

function Search({home = false,mode="view", showButtons = false, route=false, placeholder="Ask a Question..", showClear = false, w="93%"}) {
  
  const [{term, loading, streaming, user}, dispatch] = useStateValue();
  const [input, setInput] = useState(term);  
  const navigate = useNavigate();
  const inputRef = useRef(null);

  // useEffect(()=>{
  //   if(!!inputRef.current && home){
  //     inputRef.current.focus();
  //   }
  // },[])

  const defocus_dismisskeyboard = () => {
    if(inputRef.current){
      inputRef.current.blur();//dismiss keyboard whenever form is submitted
    }
  }

  const search = (e, edit=false) => {
    e.preventDefault();  

    defocus_dismisskeyboard();
    
    if(input === "" || loading || streaming) 
      return;

    postFunc(edit);
    dispatch({type:actionTypes.SET_SEARCH_TERM, term: input });
    dispatch({type:actionTypes.SET_LOADING, loading: true });
    submitCustomEvent('Input_P', input);
    setInput("");
  }

  const postFunc = (edit) => {
    if(route){
    
      navigate("/query")
    // if(edit){
    //   navigate("/docs")
    // } else{
    //   navigate("/query");
    // }
      
  } }

  const getHomeStyle = ()=> {
    let style =  {marginBottom:"5%", width:w };
    return home ? {...style, height:"unset", borderRadius: "18px", boxShadow:"2px 4px 12px rgba(0,0,0,.5)"}: {...style, width: "78vw"};
  }

  return (
    <div>
      {/* {!edit && <Button className='askai'>+ Start Again, Ask a new Question</Button>} */}
      {/* { edit && */}
      <form className='search' onSubmit={search}>
          <div className= { "search__input"} style={getHomeStyle()}>
              <SearchIcon style={{opacity:0.65, alignSelf: home  && "flex-start", paddingTop: home && "0.1rem"}} className='search__icon' />
              {!home && <input ref={inputRef} value={input} onChange={e=> setInput(e.target.value)} placeholder={placeholder}/>}
              {home && <textarea rows={7} ref={inputRef} value={input} onChange={e=> setInput(e.target.value)} placeholder={"Ask AI a Question.."}/>}
              {showClear && <ClearIcon style={{opacity:0.65}} onClick={(_)=>setInput("")}/>}
              {/* {showButtons && <SendIcon style={{alignSelf:"flex-end" }} type="submit" onClick={search}/>} */}
              {
              
              showButtons 
              &&                 
            
         
                  
              <SendIcon type="submit" onClick={search} style={ home && {"color":"black", alignSelf:"flex-end", fontSize:"1.9rem", marginRight:"5px", opacity:"0.9"}}/>
            

                // <div className='askai' style={{alignSelf:"flex-end"}}>
                //   <Button onClick={(e)=>{
                //     search(e);
                //   }} type='submit'>Write with AI</Button>
                  
                //   <SendIcon type="submit" onClick={search} style={ home && {"color":"white"}}/>
                // </div>
                //   <div style={{display:"flex", alignItems:"center", alignSelf:"flex-end"}}>
                // <MicIcon style={{padding:"5px"}}/>
                // </div>

          }

          {/* {
            showButtons && <div className='askai' style={{alignSelf:"flex-end"}}>
              <Button onClick={(e)=>{
                console.log("Button clicked")
                search(e, true);
              }}>Edit</Button>
              <CreateIcon type="submit" onClick={search} style={ home && {"color":"white"}}/>
            </div>
          } */}
          </div>
        {/* {showButtons && <div className='askai'>
              <Button type='submit'>Ask AI</Button>
              <SendIcon type="submit" onClick={search}/>
          </div>} */}
          {mode == "landing" && (<LandingPrompts dispatch={dispatch} streaming={streaming} postFunc={postFunc} user={user}/>)}
          {/* {mode == "view" && (<PromptCarousel dispatch={dispatch} streaming={streaming} postFunc={postFunc}/>)} */}
        
          {/* <Carousel>
            <div className='prompt_assistance_button_group_countainer'>
              <div className='prompt_assistance_button_group'>  
                <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write a short Happy birthday greeting for a friend use [name], add emoji" })}}>Write Birthday greeting</Button>
                <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Compare iPhone 14 pro and Samsung S22, give output in table form, give synthesis of user feedback, assume user is in India" })}}>iPhone 14 pro vs Samsung S22</Button>
                
              </div>
              <div className='prompt_assistance_button_group'>
              <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write an OOO email informing that you are not well today" });}}>Write OOO email, not well</Button>
              <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write an essay on stock market, include sub-titles" });}}>Essay on Stock Market</Button>   
              </div>
            </div>
            <div className='prompt_assistance_button_group_countainer'>
              <div className='prompt_assistance_button_group'>
              <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write a LinkedIn post about my new job, add emojis" })}}>LinkedIn new job post</Button>
                <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write a cover letter for a product manager opening" });}}>Cover letter, Product Manager</Button>
              
              </div>
              <div className='prompt_assistance_button_group'>
              <Button onClick={(_)=>{ if (streaming){return;}dispatch({type:actionTypes.SET_SEARCH_TERM, term: "What is (x+y)^3, when x=32 and y=23" });}}>Solve: (x+y)^3, x=32, y=23</Button>
                <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write a tweet about Virat Kohli" });}}>Tweet on Kohli</Button>
              </div>
            </div>
            
            <div className='prompt_assistance_button_group_countainer'>
            <div className='prompt_assistance_button_group'>
            <Button onClick={(_)=>{ if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "How to become an Instagram influencer? add emojis" })}}>Become Instagram influencer</Button>
                <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write a Happy Thanksgiving greeting with emojis" })}}>Thanksgiving greeting</Button>
              </div>
              <div className='prompt_assistance_button_group'>
              <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Write a Facebook post on India Independence day" })}}>India Independence day FB post</Button>
                <Button onClick={(_)=>{if (streaming){return;} dispatch({type:actionTypes.SET_SEARCH_TERM, term: "Give me a tutorial on Python Programming" });}}>Tutorial on Python</Button>
              </div>
            </div>
          </Carousel> */}
      </form>
      {/* } */}
    </div>
  )
}

export default Search