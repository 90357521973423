import React from "react";
import "./BasicChat.css";
import { useNavigate } from "react-router-dom";

//non  position: "fixed",
const Footer = ({position = "fixed"}) => {
    const location = useNavigate();

    const getStyle = (position) => {
        if(position == "fixed"){
            return { background: "black", position: position, opacity: "0.9", bottom: "0", width: "100%" };
        }else{
            return { background: "black", opacity: "0.9", bottom: "0", width: "100%" };
        }
    }

    return (
        <div className="footer" style={getStyle(position)}>
            <div className="footer__inner" style={{ padding: "1.5rem", color: "white", display: "flex", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>

                {/* <div style={{margin:"0 1.8%"}}>Privacy Policy</div> */}
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai">Contact Us</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai?subject=Re:%20Careers&body=Please%20attach%20your%20CV">Careers</a></div> */}
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/privacy")}>Privacy Policy</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/customer-support")}>Support</a></div>
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/terms-of-service")}>Terms of Service</a></div> */}
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/customer-support")}>Support</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/terms-of-service")}>Terms</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/cancellation")}>Cancellation</a></div> */}
                {/* <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/about")} >About Us</a></div>
                <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} onClick={(_)=>location("/refund")} >Refund Policy</a></div> */}
            </div>

        </div>
    );
}

export default Footer;