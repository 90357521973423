import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthWrapper } from './AuthWrapper';
import Header from './Header';
import Footer from './Footer';

const TermsOfService = () => {
  const navigate = useNavigate();
  useAuthWrapper();

  return (
    <div>
      <div className="container">
        <Header showLogin={false} />
        <div style={{ marginTop: '2%' }}>
          <div className="">
            <div className="privacy-policy" style={{ marginTop: '7%' }}>
              <h1 style={{ fontWeight: 400 }}>Terms of Service </h1> <br/>
              <i style={{fontSize:"0.8rem"}}>*including Cancellation and Refund Policy</i><br/>
              <br />
              <p>
                Last updated: December 4th, 2023
              </p>
              <br /><br />
              <p>
                Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the www.writechat.ai website (the “Service”) operated by WriteChat.ai (“us”, “we”, or “our”). 
              </p>
              <br/>
              <p>
                Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
              </p>
              <br/>
              <p>
                By accessing or using the Service, you agree to be bound by these Terms. WriteChat.ai reserves the right, in its sole discretion, to restrict, suspend, or terminate your access to and use of the Service.
              </p>
              <br/>
              <h2>Our Services</h2>
              <br />
              <p>
                WriteChat.ai provides a text generation service for writing assistance. WriteChat.ai is available via <a href="https://www.writechat.ai">https://www.writechat.ai</a> and associated internet domains.
              </p>
              <br/>
              <h2>Acceptable Use</h2>
              <br />
              <p>
                You must be at least 13 years old to use the Service. If you are under 18, you must have your parent or legal guardian’s permission to use the Service. You agree not to share, transfer, or sell your account. You are responsible for all activities on your account.
              </p>
              <p>
                You agree not to use the Service in any way that causes, or may cause, damage to the Service or impairment of the availability or accessibility of the Service. You agree not to use the Service for unlawful, illegal, fraudulent, or harmful activities, including but not limited to hate, harassment, violence, political manipulation, spam, or malware.
              </p>
              <p>
                You agree not to conduct any systematic or automated data collection activities (including scraping, data mining, data extraction, or data harvesting) on or in relation to the Service. Prohibited data collection includes, but is not limited to, using the Service as input into other services, websites, or databases. You agree not to republish any content generated by the Service without clearly citing the Service as well as the context associated with the content. Misrepresentation of the source of the content or the nature of its creation is strictly prohibited.
              </p>
              <br/>
              <h2>Billing</h2>
              <br/>
              <p>
                 Billing. If you purchase any Services, you will provide complete and accurate billing information, including a valid payment method. You’re responsible for all applicable taxes, and we’ll charge tax when required. If your payment cannot be completed you will not be able to access our Services until payment is received. 
              </p>
              <br/>
              <h2>Refund Policy</h2>
              <br/>
              <p>
                 Payments are non-refundable, except where required by law. These Terms do not override any mandatory local laws regarding your cancellation rights. 
              </p>
              <br/>
              <h2>Discontinuation of Services</h2>
              <br/>
              <p>
              
               We may decide to discontinue our Services, but if we do, we will give you advance notice and a refund for any prepaid, unused Services.
              </p>
              <br/>
              <h2>Cancellation Policy</h2>
              <br />
              <p>
                You can cancel your WriteChat.ai account at any time by contacting our support team at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>. Please note that certain terms and conditions may apply to account cancellations, and any applicable fees or refunds will be subject to our cancellation policy.
              </p>
              <br/>
              <h2>Contact Us</h2>
              <br />
              <p>If you have any questions about these Terms, please contact us at <a href="mailto:support@writechat.ai">support@chatdragon.ai</a>.</p>
              <br/>
            </div>
          </div>
        </div>
      </div>
      <Footer position='test' />
    </div>
  );
};

export default TermsOfService;



// import React from 'react';
// import { useNavigate } from "react-router-dom"
// import { useAuthWrapper } from './AuthWrapper';
// import Header from './Header';
// import Footer from './Footer';

// const TermsOfService = () => {

//     const navigate = useNavigate();
//     useAuthWrapper();

//     return (
//         <div>
//             <div className="container">
//                 <Header showLogin={false} />
//                 <div style={{ marginTop: "2%" }}>
//                     <div className="">
//                         <div className="privacy-policy" style={{ marginTop: "7%" }}>
//                             <h1 style={{fontWeight:400}}>Terms Of Service</h1>
//                             <br />
//                             <p><i>Updated: December 4th, 2023</i></p>
//                             <br /><br />
//                             <p>
//                                 Thank you for using WriteChat.ai!

//                                 These Terms of Use apply to your use of writing assistance services offered by WriteChat.ai.  
//                             </p>
//                             <h2>Our Services</h2>
//                             <br />
//                             <p>
//                                 WriteChat.ai offers a text generation service for writing assistance. WriteChat.ai can be accessed through{' '}
//                                 <a href="https://www.writechat.ai">https://www.writechat.ai</a> and its associated internet domains.
//                             </p>
//                             <br /><br />
//                             <h2>Information WriteChat.ai Collects</h2>
//                             <br />
//                             <p>
//                                  We require your registered company address and domestic telephone number in India to be listed on the "Contact Us" or "Support" page on your website.
//                             </p>

//                             {/* Add more sections here... */}

//                             <br /><br />
//                             <h2>Contact Us</h2>
//                             <br />
//                             <p>If you have any inquiries regarding this Policy, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
//                         </div>

//                     </div>

//                 </div>

//             </div>
//             <Footer />
//         </div>


//     );
// };

// export default TermsOfService;
